import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../../components/Layout/Layout"
import Seo from "../../components/Seo"
import AudioPlayer from "react-h5-audio-player"
import PlayIcon from "../../components/svgs/PlayIcon"
import PauseIcon from "../../components/svgs/PauseIcon"
import EntryHeader from "../../components/SingleTemplateComponents/EntryHeader"
import ShareBar from "../../components/SingleTemplateComponents/ShareBar"
import ComponentParser from "../../utils/ComponentParser"
import VideoEmbed from "../../components/SingleTemplateComponents/VideoEmbed"

const Lecture = ( { data: { lecture } } ) => {

	return (
		<Layout>
			<Seo
				title={lecture.title}
				canonical={lecture.seo?.canonical}
				uri={lecture.uri}
				socialImage={lecture.featuredImage?.node}
				description={lecture.seo?.metaDesc ? lecture.seo.metaDesc : lecture.excerpt.replace(/(<([^>]+)>)/gi, "") }
			/>

			{ lecture?.resourceMeta?.videoEmbed ?
				<div>
					<VideoEmbed 
						video={lecture.resourceMeta.videoEmbed}
					/>
				</div>

				:
				<div>
					{ lecture.resourceMeta?.audiofile?.localFile?.publicURL &&
						<AudioPlayer
							src={lecture.resourceMeta.audiofile.localFile.publicURL}
							showSkipControls={false}
							showJumpControls={false}
							layout="horizontal"
							customIcons={ {play: <PlayIcon />, pause: <PauseIcon /> }}
						/>
					}
				
					{ ! lecture.resourceMeta?.audiofile?.localFile?.publicURL && lecture.resourceMeta?.audiofile?.mediaItemUrl &&
						<AudioPlayer
							src={lecture.resourceMeta.audiofile.mediaItemUrl}
							showSkipControls={false}
							showJumpControls={false}
							layout="horizontal"
							customIcons={ {play: <PlayIcon />, pause: <PauseIcon /> }}
						/>
					}
				</div>
			}

			<EntryHeader
				title={lecture.title}
				date={lecture.date}
				icon={ lecture?.organizations?.nodes[0]?.organizationMeta?.logo?.localFile }
				format='Lecture'
			/>

			<ShareBar 
				url={lecture.uri}
			/>

			<div className="max-w-2xl mx-auto mt-4 md:-mt-64 pb-16 px-4 md:px-0 min-h-[40vh]">
				{lecture.blocks &&
				lecture.blocks.map((block, i) => {
					return (
						<div key={i}>
							<ComponentParser
								block={block}
								page={lecture}
							/>
						</div>
					)
				})}
				{ lecture.resourceMeta?.sourceUrl &&
						<p className="mt-12">
							This content was originally published on  
							
							<Link 
								to={lecture.resourceMeta.sourceUrl}
								target="_blank"
							>
								{lecture?.organizations?.nodes[0] ?
									' ' + lecture.organizations.nodes[0].name
								: 
									lecture.resourceMeta.sourceUrl
								}
							</Link>
						</p>
					}
			</div>
				
		</Layout>
	);
}

export const lectureQuery = graphql`
	query lectureById(
		$id: String!
	) {
		lecture: wpLecture( id: { eq: $id } ) {
			... LectureContent
		}
	}
`
export default Lecture